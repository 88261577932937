var delayInMilliseconds = 1000; //1 second
setTimeout(function() {
    const logo_top = document.querySelector(".home-fade-in  .logo-top");
    if(logo_top && logo_top.length){
        logo_top.classList.add("fade-in");
    }
}, delayInMilliseconds);

const second_logo = 2000; //1 second
setTimeout(function() {
    const logo_bottom = document.querySelector(".home-fade-in .logo-bottom");
    if(logo_bottom && logo_bottom.length){
        logo_bottom.classList.add("fade-in");
    }
}, second_logo);

jQuery(document).ready(function ($) {

    $('.hamburger').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('nav-close, is-active');
        $('body').toggleClass('nav-open');
    });

    if ($('.scroll-link').length) {
        $('.scroll-link a').on('click', function (e) {
            e.preventDefault();

            var target = $(this).attr('href');
            $('html, body').animate({
                'scrollTop': $(target).offset().top - 50
            }, 1000);

        });
    }

    if ($(window).width() < 992) {

        jQuery('.sh__content__menu .sub-menu').prepend('<li class="menu-back"><a href="#"><b>< Back</b></a></li>');
    }

    jQuery('.header-main-nav .menu-item-has-children').prepend('<span><i class="far fa-angle-right"></i></span>');

        $('.menu-item-has-children span').on('click', function (e) {
            e.preventDefault();
            $(this).siblings('.sub-menu').addClass('in');
        });


        $('.back a').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.sub-menu').removeClass('in');
        });

        $('.menu-back').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.sub-menu').removeClass('in');
        });


    if ($(window).width() > 767) {
        var $sticky = jQuery('.sticky');
        var $stickyrStopper = jQuery('footer');

        if (!!$sticky.offset()) { // make sure ".sticky" element exists

            var generalSidebarHeight = $sticky.innerHeight();
            var stickyTop = $sticky.offset().top;
            var stickOffset = 70;
            var stickyStopperPosition = $stickyrStopper.offset().top;
            var stopPoint = stickyStopperPosition - generalSidebarHeight - stickOffset;
            var diff = stopPoint + stickOffset;

            $(window).scroll(function () { // scroll event
                var windowTop = $(window).scrollTop(); // returns number
                console.log('windowTop is ' + windowTop);
                console.log('stopPoint is ' + stopPoint);

                if (stopPoint < windowTop) {
                    $sticky.css({position: 'sticky', top: '20px', height: '100%'});
                }
            });

        }
    }

// Smooth anchor scroll
    jQuery('a[href*="#"]:not([href="#"])').click(function () {
        var offset = -50; // <-- change the value here
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top + offset
                }, 1000);
                return false;
            }
        }
    });

    // All items
    var nav = jQuery('.sh__content__nav');
    if (nav) {

        jQuery('.sh__content__nav').waypoint(function(direction) {
            if (direction == 'down') {
                jQuery(this.element).addClass('header-fixed');
            }
            else {
                jQuery(this.element).removeClass('header-fixed');
            }
        }, { offset: '10%' });
    }

    jQuery('.grid-tile-reveal, .content-image--image-reveal').each(function(i) {
        jQuery(this).waypoint(function() {
            jQuery(this.element).clearQueue().delay(200*i).queue(function(){ jQuery(this).addClass('reveal--show'); });
        }, { offset: '80%'});
    });


    // Calculate the total on the expensses form
    const totalInput = document.getElementById('input_92_86');

    // Get all the value inputs whiich we will then calculate and add to the total
    if (totalInput) {

        // Get all the divs with the class "claim-amount" and then find the inputs inside them
        const claimAmounts = document.querySelectorAll('.claim-amount input');

        // On cahnge of any of the claimAmounts update the totalInput value
        claimAmounts.forEach(function(claimAmount) {
            claimAmount.addEventListener('input', function() {
                let total = 0;

                // Loop through each claim amount input
                claimAmounts.forEach(function(claimAmount) {
                    
                    // Parse the value to a number and add it to the total
                    if (claimAmount.value && claimAmount.value !== 0) {

                        // remove "£ " from the vclaimAmount.value to cleanup the Gravity Forms output
                        const cleanValue = claimAmount.value.replace('£ ', '');

                        total += parseFloat(cleanValue);
                    }
                });

                // Set the total input value to the calculated total
                totalInput.value = total.toFixed(2);
            });
        });

        // Calculate the total on page load
        let total = 0;

        // Loop through each claim amount input
        claimAmounts.forEach(function(claimAmount) {
            // Parse the value to a number and add it to the total
            total += parseFloat(claimAmount.value);
        });

        // Set the total input value to the calculated total
        totalInput.value = total.toFixed(2);
    }

});